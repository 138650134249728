/*
 * Catalog.js
 */
import React, { Component } from 'react';
import Button from '../Button';
import { isAdmin, joinJsonObjects } from '../../util/util';

/*
 * Schema for a catalog of levels or courses
 * type: can be "curriculum" or "level"
 */

//  const myCatalog = {
//   "type": "curriculum",
//   "title": "Curriculum",
//   "titleDesc": "CS Wonders curriculum is structured with 9 levels, each containing 8 courses.",
//   "titleImage": "/img/curriculum.jpg",
//   "items": [
//     {
//       "title": "Level 1",
//       "color": "rgba(1, 2, 3, 1)",
//       "link": "/level1",
//       "desc": "Level 1 provides an overview of computer science",
//       "image": "/img/level1.jpg"
//     },
//     {
//       "title": "Level 2",
//       "link": "/level2",
//       "desc": "Level 2 continue to expand the topics",
//       "image": "/img/level2.jpg"
//     }
//   ]
// };

/*
 * style for catalog title
 */
var catalogTitleTable = {
  //"backgroundImage": "url('/img/clouds.png')",
  "backgroundColor": "#37A7D7",
  //"backgroundColor": "rgba(48, 165, 217, 1)",
  //"backgroundColor": "white",
  "text": "white",
};

var catalogLevelTitleTable = {
  "backgroundColor": "#37A7D7",
  //"backgroundImage": "url('/img/skybanner1.jpg')",
  "margin": "auto",
  "width": "100%",
};

var catalogTitleImg = {
  "textAlign": "right",
  "paddingLeft": "10px",
};

var catalogTitleDesc = {
  "fontSize": "18px",
  "textAlign": "left",
  "color": "white",
  "padding": "20px"
};

var catalogLevelTitleDesc = {
  "fontSize": "18px",
  "textAlign": "left",
  "color": "white",
  "padding": "20px"
};

/* 
* style for catalog items
*/
var catalogItemsBackGround = {
  // "backgroundColor": "rgba(149, 209, 27, 1)",
  "backgroundColor": "#f6f8f8",
  "padding": "20px",
  "textAlign": "center"
};

var catalogItemsContainer = {
  "maxWidth": "1600px",
  "textAlign": "center",
  "margin": "auto",
};

/*
 * style for item badge
 */
var catalogItemBadge = {
  "borderRadius": "10px",
  "backgroundColor": "#FFF",
  "maxWidth": "800px",
  "marginLeft":"auto",
  "marginRight":"auto",
  "minHeight":"260px",
  "border": "1px solid #e2d8e8",
};

var catalogItemTable = {
  "textAlign": "center",
  "width": "100%",
};

var catalogItemTitle = {
  "borderTopLeftRadius": "10px",
  "borderTopRightRadius": "10px",
  //"background": "rgba(102, 102, 102, 1)",
  "color": "#FFF",
  "fontFamily": "'Helvetica Neue',Helvetica,Arial,sans-serif",
  "fontSize": "20px",
  "textAlign": "left",
  "padding": "6pt",
  "paddingLeft": "22pt",
};

var catalogItemImg = {
  "width": "100pt",
  "textAlign": "right",
};

var catalogItemDesc = {
  "padding": "20px",
  "fontFamily": "'effra', 'Avenir', sans-serif",
  "fontSize": "16px",
  "textAlign": "left",
  "verticalAlign": "top",
  "color": "#999999",
};

/*
 * item button
 */
var catalogItemButton = {
  base: {
    "padding": "4px",
    "display": "inline-block",
    "marginRight": "30px",
    "borderRadius": "2em",
    "fontWeight": "500",
    "letterSpacing": "0.03125em",
    "fontSize": "130%",
    "lineHeight": "2.2",
    "border": "2px solid rgba(135, 204, 2, 1)",
    "color": "rgba(135, 204, 2, 1)",
    "backgroundColor": "#FFF",
    "marginBottom": "20px",
    "width": "220px",
    "textAlign": "center",

    ':hover': {
      "border": "2px solid rgba(135, 204, 2, 1)",
      "backgroundColor": "rgba(135, 204, 2, 1)",
      "color": "white",
      "textDecoration": "none"
    },
    ':focus': {
      "outline" :"0",
    }
  }
};

var catalogItemSmallButton = {
  base: {
    "padding": "4px",
    "display": "inline-block",
    "marginRight": "10px",
    "borderRadius": "1em",
    "fontWeight": "400",
    "letterSpacing": "0.03125em",
    "fontSize": "90%",
    "lineHeight": "2",
    "border": "2px solid rgba(135, 204, 2, 1)",
    "color": "rgba(135, 204, 2, 1)",
    "backgroundColor": "#FFF",
    "marginBottom": "20px",
    "width": "90px",
    "textAlign": "center",

    ':hover': {
      "borderColor": "rgba(135, 204, 2, 1)",
      "backgroundColor": "rgba(135, 204, 2, 1)",
      "color": "white",
      "textDecoration": "none"
    },
    ':focus': {
      "outline" :"0"
    }
  }
};

class ItemBadge extends Component {
  componentDidMount() {
    this.setState({
      user: this.props.store ? this.props.store.getState().auth.user : undefined,
    });
  }

  buttons() {
    var type = this.props.type;
    var item = this.props.item;

    if (type === 'course') {
      return (
        isAdmin(this.state.user) ? 
          <div>
            <a href={ 'course?id=' + item.id }>
              <Button style={catalogItemButton}> Edit Course </Button>
            </a>

            <a href={item.link}>
              <Button style={catalogItemButton}> Start Learning </Button>
            </a>
          </div>
        :
          <div>
            <a href={item.link}>
              <Button style={catalogItemButton}> Start Learning </Button>
            </a>
          </div>
      );
    }

    if (type === 'curriculum') {
      return (
        <div>
          <a href={item.link}>
            <Button style={catalogItemButton}> Start Tutorial </Button>
          </a>
        </div>
      );
    }

    if (type === 'level') {
      if (!item.linkTutorial)
        return <div></div>;

      return (
        <div>
          <a href={item.linkTutorial}>
            <Button style={catalogItemSmallButton}> Tutorial </Button>
          </a>
          <a href={item.linkChallenge}>
            <Button style={catalogItemSmallButton}> Challenge </Button>
          </a>
          <a href={item.linkTask}>
            <Button style={catalogItemSmallButton}> Task </Button>
          </a>
        </div>
      );
    }

    return (
      <div></div>
    );
  }

  /*
   * render for ItemBadge
   */
  render() {
    if (!this.state)
      return <div></div>

    var item = this.props.item;
    // var itemTitle = (this.props.type === 'level') ? this.props.level + '.' + (this.props.id + 1) + ' ' + item.title : item.title;
    var itemTitle = (this.props.type === 'level') ? 'Module ' + (this.props.id + 1) + ': ' + item.title : item.title;

    return (
      <div style={catalogItemsContainer}>
        <div style={catalogItemBadge}>

          <table style={catalogItemTable}>
            <tbody>
              <tr>
               <td colSpan="3" style={joinJsonObjects(catalogItemTitle, {"backgroundColor": item.color})}>
                {itemTitle}
              </td>
              </tr>

              <tr>
                {
                  // <td style={catalogItemImg}>
                  //   <img src={item.image} width="100" height="100" alt="Item"></img>
                  // </td>
                }

                <td width="10">
                </td>

                <td style={catalogItemDesc}>
                  {item.desc}
                </td>
              </tr>

              <tr>
               <td colSpan="3" style={{"textAlign":"right"}}>
                {this.buttons()}
              </td>
              </tr>

            </tbody>
          </table>

        </div>
      </div>
    );
  }

};

/*
 * class Catalog
 */
class Catalog extends Component {
  getItems() {
    var items = this.props.catalog.items;
    var propType = this.props.catalog.type;
    var level = this.props.catalog.title;
    level = level.slice(6);

    var results = [];
    var i = 0;

    for(i = 0; i < items.length - 1; i = i + 2) {
      results.push(
        <div className="row">
          <div className="col-md-6">
            <ItemBadge key={items[i].title} item={items[i]} id={i} type={propType} level={level} store={this.props.store} />
            <br />
          </div>
          <div className="col-md-6">
            <ItemBadge key={items[i+1].title} item={items[i+1]} id={i+1} type={propType} level={level} store={this.props.store} />
            <br />
          </div>
        </div>
      );
    }

    if ((items.length - i) == 1) {
      results.push(
        <div className="row">
          <div className="col-md-6">
            <ItemBadge key={items[i].title} item={items[i]} id={i} type={propType} level={level} store={this.props.store} />
            <br />
          </div>
        </div>
      );
    } 

    return results;

    // for(i = 0; i < items.length - 2; i = i + 3) {
    //   results.push(
    //     <div className="row">
    //       <div className="col-md-4">
    //         <ItemBadge key={items[i].title} item={items[i]} id={i} type={propType} level={level} store={this.props.store} />
    //         <br />
    //       </div>
    //       <div className="col-md-4">
    //         <ItemBadge key={items[i+1].title} item={items[i+1]} id={i+1} type={propType} level={level} store={this.props.store} />
    //         <br />
    //       </div>
    //       <div className="col-md-4">
    //         <ItemBadge key={items[i+2].title} item={items[i+2]} id={i+2} type={propType} level={level} store={this.props.store} />
    //         <br />
    //       </div>
    //     </div>
    //   );
    // }

    // if ((items.length - i) == 1) {
    //   results.push(
    //     <div className="row">
    //       <div className="col-md-4">
    //         <ItemBadge key={items[i].title} item={items[i]} id={i} type={propType} level={level} store={this.props.store} />
    //         <br />
    //       </div>
    //     </div>
    //   );
    // } else if ((items.length - i) == 2) {
    //   results.push(
    //     <div className="row">
    //       <div className="col-md-4">
    //         <ItemBadge key={items[i].title} item={items[i]} id={i} type={propType} level={level} store={this.props.store} />
    //         <br />
    //       </div>
    //       <div className="col-md-4">
    //         <ItemBadge key={items[i+1].title} item={items[i+1]} id={i+1} type={propType} level={level} store={this.props.store} />
    //         <br />
    //       </div>
    //     </div>
    //   );
    // }

    // return results;
  }

  getTitleDesc() {
    if (this.props.catalog.type === 'curriculum' || this.props.catalog.type === 'course') {
      return (<div></div>);
    }

    var titleStyle = (this.props.catalog.type === 'level') ? catalogLevelTitleTable : catalogTitleTable;
    var titleDescStyle = (this.props.catalog.type === 'level') ? catalogLevelTitleDesc : catalogTitleDesc;
    return (
      <table style={titleStyle}>
        <tbody>
          <tr>
            <td style={catalogTitleImg}>
              <img src={this.props.catalog.titleImage} width="80" height="80" alt=""></img>
            </td>

            <td width={"0"}>
            </td>

            <td style={titleDescStyle}>
              <div>
                <p>{this.props.catalog.titleDesc}</p>
              </div>
            </td> 
          </tr>
        </tbody>
      </table>
    );
  }

  /*
   * render for Catalog
   */
  render() {
    var items = this.getItems();
    var titleDesc = this.getTitleDesc();

    return (
      <div>
        { titleDesc }

        <div style={catalogItemsBackGround}>

          <div style={catalogItemsContainer}>

            <div>
                { items }
            </div>

          </div>

        </div>

      </div>
    );

  }
}

export default Catalog;